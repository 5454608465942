import axios from "axios";
import { message } from "ant-design-vue";
const BASE_URL = process.env.VUE_APP_BASE_API;
//const BASE_URL = '/api';
import router from "../router";
const codeInfo = [];
const instance = axios.create({
  timeout: 100000,
  baseURL: BASE_URL,
  headers: {
    "content-type": "application/x-www-form-urlencoded;charset=UTF-8",
  },
});

instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    const msg = error.message ? error.message : "";
    message.error("请求错误" + msg);
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (response) => {
    if (response && response.status === 200 && response.data.code === "0000") {
      return Promise.resolve(response);
    } else {
      if (response && response.status === 200 && response.data.code === "4007")
        router.replace({
          path: "/user/login",
        });
      message.error(response.data.msg);
      return Promise.reject(response);
    }
  },
  (error) => {
    let errCode =
      (error.response && error.response.data && error.response.data.code) ||
      error.status;

    if (errCode == 30010003 && error.response.config.url === "/v1/auth/token") {
      message.error("账号或者密码错误");
    } else if (errCode == 30010002 || errCode === 30001002) {
      message.error("登录失效，请重新登录！");
      router.replace({
        path: "/login",
      });
    } else if (
      errCode == 30010001 ||
      errCode == 30010004 ||
      errCode == 50010001 ||
      errCode == 50010002 ||
      errCode == 30070001
    ) {
      message.error("登录失效，请重新登录！");
      router
        .replace({
          path: "/login",
        })
        .catch(() => {});
    } else {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        var text = codeInfo[errCode];
        if (text) {
          message.error(text);
        } else {
          message.error(error.response.data.message);
        }
      } else if (error.response && error.response.data) {
        message.error("请求失败");
      } else {
        const msg = error.message ? error.message : "";
        message.error("网络错误" + msg);
      }
    }
    return Promise.reject(error);
  }
);

function postJson(url, data) {
  let token = localStorage.getItem("token") || "";
  return instance({
    url: url,
    data: data,
    method: "POST",
    headers: {
      "content-type": "application/json;charset=UTF-8",
      Authorization: token || "",
    },
  });
}
function uploadFile(url, data) {
  let token = localStorage.getItem("token") || "";
  return instance({
    url: url,
    data: data,
    method: "POST",
    headers: {
      "content-type": "multipart/form-data",
      Authorization: token || "",
    },
  });
}
function exportExcelMethod(data) {
  let token = localStorage.getItem("token") || "";
  axios({
    method: data.methods,
    url: data.url,
    params:data.params,
    headers: {
      "content-type": "multipart/form-data",
      "Authorization": token || "",
    },
    responseType: "blob",
  })
    .then((res) => {
      const link = document.createElement("a");
      let blob = new Blob([res.data], {
        type: "application/vnd.ms-excel",
      });
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.download = data.fileName + ".xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      console.log("error");
    });
}

export default {
  postJson: postJson,
  uploadFile: uploadFile,
  exportExcelMethod: exportExcelMethod,
};
