import HTTP from "./http";

function API() {}
API.prototype = {
  // 登录
  Login: function (data) {
    return HTTP.postJson("/login/login", data);
  },
  // 获取短信验证码
  getSmsCode: function (data) {
    return HTTP.postJson("/login/sms", data);
  },
  // 获取oss token 接口
  getStsToken: function (data) {
    return HTTP.postJson("/oss/token", data);
  },

  // 新增拍卖会
  newAuction: function (data) {
    return HTTP.postJson("/auction/add", data);
  },

  // 拍卖会列表
  auctionList: function (data) {
    return HTTP.postJson("/auction/page", data);
  },

  // 编辑拍卖会
  editorAuction: function (data) {
    return HTTP.postJson("/auction/editor", data);
  },

  // 更新拍卖会状态
  updateStatusAuction: function (data) {
    return HTTP.postJson("/auction/updateStatus", data);
  },

  // 删除拍卖会
  deleteAuction: function (data) {
    return HTTP.postJson("/auction/delete", data);
  },

  // 拍品列表
  byAuctionIdList: function (data) {
    return HTTP.postJson("/lots/byAuctionId", data);
  },
  // 新增腕表
  newlots: function (data) {
    return HTTP.postJson("/wristWatch/add", data);
  },
  // 删除腕表
  deletelots: function (data) {
    return HTTP.postJson("/wristWatch/delete", data);
  },
  // 编辑拍品
  editorwatch: function (data) {
    return HTTP.postJson("/wristWatch/editor", data);
  },
  //全部品牌列表
  allBrandList: function (data) {
    return HTTP.postJson("/brand/list", data);
  },

  //启用禁用拍品
  updateLotsStatus: function (data) {
    return HTTP.postJson("/lots/status", data);
  },
  // 修改密码
  changePass: function (data) {
    return HTTP.postJson("/login/changePassword", data);
  },
  // 新增用户
  addUser: function (data) {
    return HTTP.postJson("/admin/add", data);
  },
  // 编辑用户
  editorUser: function (data) {
    return HTTP.postJson("/admin/editor", data);
  },
  //根据userId查询用户
  userById: function (data) {
      return HTTP.postJson("/admin/byId", data);
  },
  // 分页查询用户
  getUserList: function (data) {
    return HTTP.postJson("/user/page", data);
  },
  // 用户导出
  userExport: function (data) {
    return HTTP.postJson("/user/exportExcel", data);
  },
  // 分页查询账号
  getAccountList: function (data) {
    return HTTP.postJson("/admin/page", data);
  },
  // 分页查询腕表登记信息
  getWatchRegisterList: function (data) {
    return HTTP.postJson("/watch-register/page", data);
  },
  // 更新腕表验证状态
  UpdateApproveStatus: function (data) {
    return HTTP.postJson("/watch-register/update/approveStatus", data);
  },
   // 解除腕表绑定状态
   RemoveBindingStatus: function (data) {
    return HTTP.postJson("/watch-register/remove/bindingStatus", data);
  },
    // 更新腕表激活状态
    updateActivityStatus: function (data) {
    return HTTP.postJson("/watch-register/update/activeStatus", data);
  },
  // 分页查询腕表主数据
  getWatchMainDataList: function (data) {
    return HTTP.postJson("/wristWatch/byPage", data);
  },

  getWatchById : function (data) {
    return HTTP.postJson("/wristWatch/byId", data);
  },

  // 分页查询腕表主数据
  getWatchBox: function (data) {
    return HTTP.postJson("/user/watch-page", data);
  },
  // 用户状态修改
  changeUserStatus: function (data) {
    return HTTP.postJson("/admin/updateUserStatus", data);
  },
  // 删除用户
  deleteUser: function (data) {
    return HTTP.postJson("/admin/delete", data);
  },
  // 导入拍品
  importItems: function (data) {
    return HTTP.uploadFile("/wristWatch/upload/excel", data);
  },
  // 导入拍品
  importItemsDrink: function (data) {
    return HTTP.uploadFile("/wristWatch/upload/excel", data);
  },
  // 检验图片是否重复
  duplicateRemoval: function (data) {
    return HTTP.postJson("/duplicateRemoval/img", data);
  },

  //修改拍品
  editorBrand: function (data) {
    return HTTP.postJson("/brand/editor", data);
  },
  // 删除拍品
  deleteBrand: function (data) {
    return HTTP.postJson("/brand/delete", data);
  },
  // 分页查询拍品
  getBrandListByPage: function (data) {
    return HTTP.postJson("/brand/page", data);
  },
  // 获取全部品牌接口
  getAllBrandList: function (data) {
    return HTTP.postJson("/brand/list", data);
  },
  /**
   * 推文视频接口
   * */
  // 新建推文，视频
  addContent: function (data) {
    return HTTP.postJson("/content/add", data);
  },
  // 修改
  editContent: function (data) {
    return HTTP.postJson("/content/editor", data);
  },
  // 分页获取
  getContentList: function (data) {
    return HTTP.postJson("/content/page", data);
  },
  // 修改推文状态
  contentStatus: function (data) {
    return HTTP.postJson("/content/status", data);
  },
  // 根据Id获取内容
  getContentById: function (data) {
    return HTTP.postJson("/content/byId", data);
  },
  // 获取全部拍卖会列表 // 获取全部拍品列表
  auctionOrlots: function (data) {
    return HTTP.postJson("/auctionOrlots/byPage", data);
  },
  // 删除推文
  deleteContent: function (data) {
    return HTTP.postJson("/content/delete", data);
  },
  // 删除推文内容块
  deleteContentItems: function (data) {
    return HTTP.postJson("/content/delete/details", data);
  },
  // 分页获取轮播图
  getBannerPageList: function (data) {
    return HTTP.postJson("/banner/page", data);
  },
  // 根据Id获取轮播图
  getBannerById: function (data) {
    return HTTP.postJson("/banner/byId", data);
  },
  // 新增轮播图
  addBanner: function (data) {
    return HTTP.postJson("/banner/add", data);
  },
  // 删除轮播图
  deleteBanner: function (data) {
    return HTTP.postJson("/banner/delete", data);
  },
  // 修改轮播图
  editBanner: function (data) {
    return HTTP.postJson("/banner/editor", data);
  },
  // 更新轮播图状态
  updateBannerStatus: function (data) {
    return HTTP.postJson("/banner/updateStatus", data);
  },
  // 分页获取角色
  getRolePageList: function (data) {
    return HTTP.postJson("/role/page", data);
  },
  // 根据Id获取角色
  getRoleById: function (data) {
    return HTTP.postJson("/role/byId", data);
  },
  // 新增角色
  addRole: function (data) {
    return HTTP.postJson("/role/add", data);
  },
  // 删除角色
  deleteRole: function (data) {
    return HTTP.postJson("/role/delete", data);
  },
  // 修改角色
  editRole: function (data) {
    return HTTP.postJson("/role/editor", data);
  },
  // 更新角色状态
  updateRoleStatus: function (data) {
    return HTTP.postJson("/role/updateStatus", data);
  },
    // 获取全部菜单列表
    getAllMenuList: function (data) {
      return HTTP.postJson("/menu/byAllList", data);
    },
  //腕表批量导入图片
  importBatchPhoto: function(data){
    return HTTP.postJson('/wristWatch/batchImportImg',data);
  },
  moveBanner:function(data){
    return HTTP.postJson('/banner/updateSort',data);
  },
  // 获取区域轮播图名称
  getBannerArea:function(data){
    return HTTP.postJson('/bannerArea/byLocation',data)
  },
  // 新增轮播图名称
  addBannerArea:function(data){
    return HTTP.postJson('/bannerArea/editor',data)
  },
  // 分页获取活动
  getActivityList: function (data) {
    return HTTP.postJson("/thirdActivity/page", data);
  },
  // 新建活动
  addActivityList: function (data) {
    return HTTP.postJson("/thirdActivity/add", data);
  },
  // 根据id获取活动详情
  activityListById: function (data) {
    return HTTP.postJson("/thirdActivity/byId", data);
  },
  // 编辑活动
  editActivityList: function (data) {
    return HTTP.postJson("/thirdActivity/editor", data);
  }
};



export default new API();
